import React, { useState } from "react";
import { format } from "date-fns";
import useToggle from "../lib/use_toggle";

function UserForm({ onReset, onSubmit, appointment }) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");
  const [timeAccepted, toggleTimeAccepted] = useToggle(false);
  const [privacyAccepted, togglePrivacyAccepted] = useToggle(false);

  function formIsValid() {
    return (
      firstname.length > 1 &&
      lastname.length > 1 &&
      email.length > 6 &&
      phone.length > 4 &&
      timeAccepted &&
      privacyAccepted
    );
  }

  return (
    <div className="appointment-form">
      <h1>
        Ihr Wunschttermin: {format(appointment, "d.MM.yyyy H:mm")}{" "}
        <button className="reset" type="button" onClick={onReset}>
          ändern
        </button>
      </h1>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(firstname, lastname, email, phone, comment, appointment);
        }}
      >
        <label htmlFor="firstame">Vorname</label>
        <input
          type="text"
          name="firstame"
          value={firstname}
          onChange={e => setFirstname(e.target.value)}
        />
        <label htmlFor="lastname">Nachname</label>
        <input
          type="text"
          name="lastname"
          value={lastname}
          onChange={e => setLastname(e.target.value)}
        />
        <label htmlFor="email">E-Mail</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <label htmlFor="phone">Telefonnummer</label>
        <input
          type="text"
          name="phone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <label htmlFor="phone">Kommentar/Hinweis/Frage</label>
        <textarea
          name="comment"
          onChange={e => setComment(e.target.value)}
          rows="3"
          wrap="hard"
          value={comment}
        />
        <p className="red">Wie geht es nun weiter? Hier die Infos:</p>
        <ul>
          <li>
            Innerhalb eines Werktages erhalten Sie Ihre Terminbestätigung. Erst
            dann ist Ihr Wunschtermin für Osteopathie gültig.
          </li>
          <li>
            Die Bezahlung in Höhe von 110 € - 150 € pro Stunde erfolgt vor Ort
            und kann bar oder mittels ec-Kartenzahlung beglichen werden.
          </li>
          <li>
            Für Ihre Krankenkasse erhalten Sie eine Rechnung, die nach der
            Gebührenordnung für Heilpraktiker ( GebüH ) erstellt wird.
          </li>
          <li>
            Eine kostenlose Stornierung ist bis zu 24 Stunden zuvor möglich.
          </li>
        </ul>
        <p className="confirmation-text">
          <input type="checkbox" onChange={toggleTimeAccepted} />
          <label>
            Hiermit melde ich mich zu einem einstündigen osteopathischen Termin
            am {format(appointment, "d.MM.yyyy")} um{" "}
            {format(appointment, "H:mm")} an.
          </label>
        </p>
        <p className="confirmation-text">
          <input type="checkbox" onChange={togglePrivacyAccepted} />
          <label htmlFor="">
            Ich habe die{" "}
            <a
              href="https://www.duesseldorf-osteopath.de/j/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzbestimmungen
            </a>{" "}
            zur Kenntnis genommen.
          </label>
        </p>
        <button type="submit" className="submit" disabled={!formIsValid()}>
          Verbindlich anmelden
        </button>
      </form>
    </div>
  );
}

export default UserForm;
