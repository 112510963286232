import {
  getDay,
  format,
  startOfWeek,
  addDays,
  addWeeks,
  endOfDay
} from "date-fns";
import { de } from "date-fns/locale";

function formatDateTimeISO8601(dateTime) {
  return format(dateTime, "yyyy-MM-dd") + "T" + format(dateTime, "HH:mm:ss");
}

function formatDateISO8601(date) {
  return format(date, "yyyy-MM-dd");
}

function formatTime(date) {
  return format(date, "H:mm");
}

function nextWorkingDay(date) {
  switch (getDay(date)) {
    case 0:
      return addDays(date, 1);
    case 6:
      return addDays(date, 2);
    default:
      return date;
  }
}

function filterAppointmentsByWeek(appointments, week) {
  const from = week.from;
  const to = endOfDay(week.to);
  return appointments.filter(
    ({ dateTime }) => dateTime >= from && dateTime <= to
  );
}

function formatWeek({ from, to }) {
  const weekDay = format(from, "w");
  const monday = format(from, "eeeeee d.MM", { locale: de });
  const friday = format(to, "eeeeee d.MM.yyyy", { locale: de });
  return `KW ${weekDay} vom ${monday} bis ${friday}`;
}

function weeks(date, numberOfWeeks) {
  const list = Array.from(Array(numberOfWeeks).keys());
  return list.map(i => {
    const currentDate = addWeeks(date, i);
    const workingDay = nextWorkingDay(currentDate);
    let beginningOfWeek = startOfWeek(workingDay, { weekStartsOn: 1 });
    let endOfWeek = addDays(beginningOfWeek, 4);
    return {
      from: beginningOfWeek,
      to: endOfWeek
    };
  });
}

export {
  nextWorkingDay,
  formatDateTimeISO8601,
  formatDateISO8601,
  formatTime,
  formatWeek,
  weeks,
  filterAppointmentsByWeek
};
