import React, { useState } from "react";
import Layout from "../components/layout";
import ChooseAppointment from "../components/choose_appointment";
import UserForm from "../components/user_form";
import Thankyou from "../components/thankyou";
import behandlungImg from "../images/osteopathie-behandlung.jpg";
import { formatDateTimeISO8601 } from "../lib/date_converter";

function IndexPage() {
  const [selected, setSelected] = useState(undefined);
  const [submited, setSubmited] = useState(false);

  return (
    <Layout>
      <main>
        <h1>Warum Osteopathie? </h1>
        <img src={behandlungImg} alt="" className="behandlung" />
        <p>
          Manchmal gerät der Körper aus der Balance und der stressige Alltag tut
          sein Übriges.
        </p>

        <p>
          Dann ist es an der Zeit , seinem Körper und Geist mehr Aufmerksamkeit
          zu schenken. Die Osteopathie kann Sie auf diesem Weg begleiten und Sie
          unterstützen, Ihr inneres Gleichgewicht wiederzufinden.
        </p>
        <p>Ein Tag oder Tag 1 - Sie entscheiden!</p>
      </main>
      <aside>
        {!selected && (
          <ChooseAppointment
            onSelect={appointment => () => setSelected(appointment)}
          />
        )}
        {selected && !submited && (
          <UserForm
            onReset={() => setSelected(undefined)}
            appointment={selected}
            onSubmit={(
              firstname,
              lastname,
              email,
              phone,
              comment,
              appointment
            ) =>
              submitFormData(
                setSubmited,
                firstname,
                lastname,
                email,
                phone,
                comment,
                appointment
              )
            }
          />
        )}
        {selected && submited && <Thankyou />}
      </aside>
    </Layout>
  );
}

async function submitFormData(
  setSubmited,
  firstname,
  lastname,
  email,
  phone,
  comment,
  appointment
) {
  const API_SERVER = process.env.GATSBY_API_SERVER || "http://localhost:3000";
  const date_time = formatDateTimeISO8601(appointment);
  const data = JSON.stringify({ reservation: { firstname, lastname, email, phone, date_time, comment } });
  const response = await fetch(`${API_SERVER}/api/v1/reservations`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: data
  });
  console.log(response);
  // const json = await response.json();
  setSubmited(true);
}

export default IndexPage;
