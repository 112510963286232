import React from "react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import useToggle from "../lib/use_toggle";
import { formatDateISO8601, formatWeek } from "../lib/date_converter";

function WeekItem({ week, onSelect, freeAppointments }) {
  const [open, toggleOpen] = useToggle(false);

  return (
    <div className="appointment-date" onClick={toggleOpen}>
      {formatWeek(week)}
      {open &&
        (freeAppointments.length > 0 ? (
          <Appointments
            freeAppointments={freeAppointments}
            onSelect={onSelect}
          />
        ) : (
          <NoAppointments />
        ))}
    </div>
  );
}

function Appointments({ freeAppointments, onSelect }) {
  let current_date, margin;
  let date;

  return freeAppointments.map(appointment => {
    date = formatDateISO8601(appointment.dateTime);
    if (date !== current_date) {
      current_date = date;
      margin = "15px";
    } else {
      margin = "0";
    }
    return (
      <div
        style={{ marginTop: margin }}
        key={formatAppointment(appointment)}
        className="appointment-time"
        onClick={onSelect(appointment.dateTime)}
      >
        {formatAppointment(appointment)}
      </div>
    );
  });
}

function NoAppointments() {
  return (
    <div className="appointment-no">
      Keine Termine in dieser Woche verfügbar
    </div>
  );
}

function formatAppointment(appointment) {
  return format(appointment.dateTime, "eeeeee d.MM.yyyy HH:mm", {
    locale: de
  });
}
export default WeekItem;
