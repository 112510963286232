import React from "react";
import PropTypes from "prop-types";
import elli from "../images/elli.png";

import "../styles/global.css";

const Layout = ({ children }) => (
  <>
    <div className="app">
      <header>
        <img src={elli} alt="" className="elli" />
        <h1>Elvira Rühl</h1>
        <h2>Ihre Osteopathin in Düsseldorf </h2>
        <div className="address">
          Praxis Elvira Rühl <br />
          Kreuzstraße 53 <br />
          40210 Düsseldorf <br />
          Tel: 0211 8628085
        </div>
      </header>
      <div className="container">{children}</div>
      <footer>
        © {new Date().getFullYear()} ∙ Praxis Elvira Rühl ∙ Kreuzstraße 53 ∙
        40210 Düsseldorf ∙ Tel. 0211 8628085 ∙{" "}
        <a href="https://www.duesseldorf-osteopath.de/j/privacy">
          Datenschutzerklärung
        </a>{" "}
        ∙ <a href="https://www.duesseldorf-osteopath.de/about/">Impressum</a>
        ∙ V. 1.1.0
      </footer>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
