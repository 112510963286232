import React from "react";

export default function Thankyou() {
  return (
    <div className="appointment-form">
      <h1>Vielen Dank.</h1>
      <p>Wir melden uns in Kürze bei Ihnen zwecks Teminbestätigung.</p>
    </div>
  );
}
