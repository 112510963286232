import { de } from "date-fns/locale";
import React, { useState, useEffect } from "react";
import { format, parseISO, addHours } from "date-fns";
import WeekItem from "./week_item";
import {
  formatDateISO8601,
  weeks,
  formatWeek,
  filterAppointmentsByWeek
} from "../lib/date_converter";

function ChooseAppointment({ onSelect }) {
  const [freeAppointments, setFreeAppointments] = useState([]);
  // fetchAppointments(week);
  const allWeeks = weeks(new Date(), numberOfWeeks());
  const firstDay = new Date();
  const lastDay = allWeeks[allWeeks.length - 1].to;
  const dateRange = {
    from: firstDay,
    to: lastDay
  };

  useEffect(() => {
    fetchAppointments(dateRange, setFreeAppointments);
  }, [freeAppointments.length]);

  return (
    <>
      {freeAppointments.length === 0 && <FullyBooked dateRange={dateRange} />}
      <Select onSelect={onSelect} freeAppointments={freeAppointments} />
    </>
  );
}

function Select({ onSelect, freeAppointments }) {
  return (
    <div className="choose-appointment">
      {freeAppointments.length > 0 && (
        <h1>Bitte wählen Sie Ihren Wunschtermin</h1>
      )}
      <div>
        {weeks(new Date(), numberOfWeeks()).map(week => (
          <WeekItem
            key={formatWeek(week)}
            week={week}
            onSelect={onSelect}
            freeAppointments={filterAppointmentsByWeek(freeAppointments, week)}
          />
        ))}
      </div>
    </div>
  );
}

function FullyBooked({ dateRange }) {
  function formatDate(date) {
    return format(date, "d.MM.yyyy", {
      locale: de
    });
  }

  return (
    <div className="fully-booked">
      <h1>Ausgebucht</h1>
      <p>
        Es tut uns sehr leid, aber alle Termine sind vom{" "}
        {formatDate(dateRange.from)} bis zum{" "}
        <span>{formatDate(dateRange.to)}</span> ausgebucht.
      </p>
    </div>
  );
}

function numberOfWeeks() {
  return 5;
}

async function fetchAppointments({ from, to }, setFreeAppointments) {
  const API_SERVER = process.env.GATSBY_API_SERVER || "http://localhost:3000";
  const response = await fetch(`${API_SERVER}/api/v1/reservable_times?from=${formatDateISO8601(from)}&to=${formatDateISO8601(to)}`);
  const json = await response.json();
  const appointments = json.data
    .map(data => {
      return { dateTime: parseISO(data.dateTime) };
    })
    .filter(appointment => appointment.dateTime >= addHours(new Date(), 1));
  setFreeAppointments(appointments);
}

export default ChooseAppointment;
